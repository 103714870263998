$red: #cb4444;
$yellow: #fabb45;

$primary-color: $yellow;
$secondary-color: $red;
$main-bg: #f6efe4;

$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;

$header-height-small: 5em;
$header-height: 10em;

:export {
  primaryColor: $primary-color;
  secondaryColor: $secondary-color;
  mainBg: $main-bg;
  headerHeightSmall: $header-height-small;
  headerHeight: $header-height;
}
