@import '../styles/variables.scss';

.button {
  background: white;
  color: black;
  border: solid #000;
  border-width: 2px 2px 5px;
  border-radius: 1.5em;

  cursor: pointer;
  display: inline-block;
  font-weight: 700;
  padding: 0.25em 0.5em;

  &:hover:not(:disabled) {
    border-bottom: 3px solid #000;
    margin-top: 2px;
  }

  &.primary {
    background: $primary-color;
  }

  &.secondary {
    color: white;
    background: $secondary-color;
  }

  &:disabled {
    pointer-events: none;

    border-color: gray;
    background: white;
    color: gray;
    cursor: not-allowed;

    img {
      opacity: 0.4;
    }
  }
}
