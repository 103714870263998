.dnd-sheet {
  max-width: 1024px;
  margin: auto;

  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  .d-and-d-character-sheet {
    padding: 20px;
    background: white;
    font-size: 10px;
    input[type='text'] {
      background: none;
      border: none;
      width: 100%;
    }
    .d-and-d-page-title {
      font-size: 42px;
      text-align: center;
      color: rgb(204, 10, 33);
      font-weight: bold;
      font-family: cursive;
    }
    textarea {
      line-height: 25px;
      background-image: linear-gradient(transparent, transparent calc(25px - 1px), rgb(207, 207, 207) 0px);
      background-size: 100% calc(25px + 0px);
      width: 100%;
      border: none;
      resize: none;
      background-position-y: -2px;
    }
    .d-and-d-box {
      border: 2px solid black;
      border-radius: 8px;
      padding: 10px;
      margin-bottom: 15px;
    }
    .d-and-d-box.noborder {
      border: none;
    }
    .d-and-d-box.square {
      border-radius: 0px;
    }
    .d-and-d-box.white {
      background-color: rgb(255, 255, 255);
      border-radius: 8px;
      padding: 10px;
    }
    .d-and-d-box.gray {
      background-color: rgb(207, 207, 207);
      border-radius: 8px;
      padding: 10px;
    }
    .d-and-d-statbox {
      font-size: 7px;
      background-color: white;
      font-weight: bold;
      border: 2px solid black;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      text-align: center;
      text-transform: uppercase;
      padding: 5px;
      margin: 5px 0 20px;
      height: 90px;
      overflow: hidden;
      white-space: nowrap;
      word-wrap: break-word;
      text-overflow: ellipsis;
      .d-and-d-statbox-modifier {
        text-align: center;
        font-size: 24px;
        input {
          text-align: center;
          font-size: 24px;
          margin: 10px 0;
        }
      }
      label.label-top {
        margin-bottom: 0;
      }
    }
    .d-and-d-statbox.type2 {
      margin-top: 0px;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
      margin: 5px 0 10px;
    }
    .d-and-d-statbox-value {
      background-color: white;
      border: 2px solid black;
      border-radius: 50%;
      width: 60%;
      margin: -40px auto 20px;
      left: 0;
      right: 0;
      input {
        text-align: center;
        font-size: 14px;
      }
    }
    .d-and-d-statrow {
      border: 2px solid black;
      border-bottom-right-radius: 5px;
      border-top-right-radius: 5px;
      height: 35px;
      text-transform: uppercase;
      position: relative;
      margin-bottom: 23px;
      .d-and-d-statrow-value {
        border: 3px solid black;
        background-color: white;
        width: 45px;
        display: inline-block;
        font-weight: bold;
        height: 45px;
        position: absolute;
        left: -4px;
        top: -7px;
        input {
          margin: 3px 0;
          text-align: center;
          font-size: 20px;
        }
      }
      .d-and-d-statrow-label {
        margin-left: 45px;
        text-align: center;
        display: inline-block;
        font-weight: bold;
        padding: 8px 0;
        font-size: 10px;
        width: calc(100% - 65px);
      }
    }
    .d-and-d-statrow.rounded {
      .d-and-d-statrow-value {
        border-radius: 50%;
        width: 50px;
        height: 50px;
        left: -7px;
        top: -9px;
        padding-top: 2px;
      }
    }
    .d-and-d-statrow.rounded.rounded-sides {
      .d-and-d-statrow-value {
        border-top-left-radius: 15px 50%;
        border-bottom-left-radius: 15px 50%;
        border-top-right-radius: 15px 50%;
        border-bottom-right-radius: 15px 50%;
      }
    }
    .d-and-d-statrow.wide-input {
      .d-and-d-statrow-value {
        width: 90px;
        input {
          margin: 6px 0;
          font-size: 16px;
        }
      }
      .d-and-d-statrow-label {
        margin-left: 85px;
        width: calc(100% - 90px);
        font-size: 8px;
        padding-top: 10px;
      }
    }
    .d-and-d-skill-circle {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      border: 1px solid black;
      vertical-align: middle;
      display: inline-block;
      cursor: pointer;
    }
    .d-and-d-skill-circle.active {
      background-color: black;
    }
    .d-and-d-skill {
      input {
        border-bottom: 1px solid black;
        width: 25px;
        margin: 0 10px;
        text-align: center;
      }
      label {
        margin-bottom: 5px;
      }
      .d-and-d-skill-hint {
        margin-left: 4px;
        color: rgb(150, 150, 150);
      }
    }
    .d-and-d-attribute-collection {
      border: 2px solid black;
      border-top-left-radius: 15px 50%;
      border-bottom-left-radius: 15px 50%;
      border-top-right-radius: 15px 50%;
      border-bottom-right-radius: 15px 50%;
      padding-bottom: 5px;
      background-color: white;
      input {
        border-bottom: 1px solid black;
        width: 100%;
        margin-top: 15px;
        font-size: 16px;
      }
      label {
        text-transform: uppercase;
      }
      .d-and-d-statbox {
        height: auto;
        input {
          border: none;
        }
      }
      .d-and-d-statbox.type2 {
        margin-bottom: 5px;
      }
    }
    .d-and-d-attribute-collection.gray {
      background-color: rgb(207, 207, 207);
    }
    .d-and-d-attribute-collection.char-name {
      input {
        padding-left: 10px;
        font-size: 24px;
        margin-top: 10px;
        margin-bottom: 5px;
        border-bottom: none;
      }
    }
    .d-and-d-statbox.shield {
      border-bottom-right-radius: 50%;
      border-bottom-left-radius: 50%;
      .d-and-d-statbox-modifier {
        input {
          margin-bottom: 2px;
        }
      }
    }
    input.d-and-d-cinput {
      text-align: center;
      font-size: 24px;
      margin: 0px 0;
    }
    input.d-and-d-linput {
      border: none;
      border-bottom: 1px solid rgb(100, 100, 100);
      color: black;
      width: 100%;
      text-align: center;
    }
    .d-and-d-gray-text {
      color: rgb(100, 100, 100);
      input.d-and-d-linput {
        border-bottom: 1px solid rgb(207, 207, 207);
      }
    }
    .d-and-d-deathsave {
      margin: 5px 0;
      label {
        text-transform: uppercase;
        width: 55px;
        text-align: right;
        margin-right: 10px;
      }
      .d-and-d-skill-circle {
        height: 12px;
        width: 12px;
      }
    }
    .d-and-d-save-success {
      .d-and-d-skill-circle.active {
        background-color: rgb(0, 50, 255);
      }
    }
    .d-and-d-save-failure {
      .d-and-d-skill-circle.active {
        background-color: rgb(204, 10, 33);
      }
    }
    .d-and-d-table {
      margin-bottom: 3px;
      thead {
        color: rgb(100, 100, 100);
        text-transform: uppercase;
      }
      input {
        background-color: rgb(225, 225, 225);
        border-radius: 5px;
        padding: 2px 5px;
        margin: 1px 0;
      }
    }
    .d-and-d-currency {
      position: relative;
      margin: 10px 0 20px;
      &:last-child {
        margin-bottom: 0;
      }
      .d-and-d-currency-label {
        margin-left: -10px;
        border: 2px solid black;
        background-color: white;
        border-radius: 50% 0 0 50%;
        width: 50px;
        z-index: 0;
        padding: 5px;
        font-size: 12px;
        display: inline-block;
        label {
          margin: 0;
          font-weight: bold;
          color: rgb(100, 100, 100);
        }
      }
      .d-and-d-currency-value {
        border: 2px solid black;
        background-color: white;
        width: 90px;
        height: 45px;
        border-top-left-radius: 15px 50%;
        border-bottom-left-radius: 15px 50%;
        border-top-right-radius: 15px 50%;
        border-bottom-right-radius: 15px 50%;
        z-index: 1;
        position: absolute;
        top: -5px;
        left: 15px;
        input {
          margin: 3px 0;
          padding: 6px 0;
          text-align: center;
          font-size: 14px;
        }
      }
    }
    .d-and-d-equipment-indent {
      &::first-line {
        text-indent: 200px;
      }
      &::nth-line(-n + 2) {
        text-indent: 200px;
      }
    }
    .d-and-d-image {
      height: 438px;
      width: 100%;
      cursor: pointer;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      &:hover {
        opacity: 0.7;
      }
    }
    .d-and-d-image.faction {
      height: 340px;
      border-radius: 50%;
      border: 3px solid black;
      background-color: white;
      margin: -10px -15px;
      width: calc(100% + 30px);
    }
    .border-right {
      border-right: 1px solid rgb(207, 207, 207);
    }
    .d-and-d-faction-input {
      position: absolute;
      text-align: center;
      top: 10px;
      left: 50%;
      margin-left: -25%;
      width: 50%;
      label {
        text-transform: uppercase;
        color: rgb(150, 150, 150);
        margin-bottom: 0;
        font-weight: bold;
      }
      input {
        background-color: rgb(225, 225, 225);
        border-top-left-radius: 50px 100%;
        border-top-right-radius: 50px 100%;
        padding: 5px 5px;
        margin: 1px 0;
        text-align: center;
        font-size: 14px;
      }
    }
    .d-and-d-spelllist {
      margin-bottom: 30px;
      .d-and-d-spell-header {
        border: 2px solid black;
        border-radius: 8px;
        position: relative;
        margin-bottom: 10px;
        .d-and-d-spell-level {
          position: absolute;
          border: 2px solid black;
          background-color: white;
          width: 30px;
          border-top-right-radius: 15px 50%;
          border-bottom-right-radius: 15px 50%;
          height: calc(100% + 10px);
          font-size: 16px;
          padding: 8px 0 8px 7px;
          left: -5px;
          top: -5px;
        }
        .d-and-d-spell-slots {
          width: 100%;
          padding-left: 30px;
          height: 36px;
          label {
            width: 100%;
            text-align: center;
            text-transform: uppercase;
            padding: 10px 0;
          }
          .d-and-d-spell-slots-total {
            width: 70px;
            display: inline-block;
            font-size: 16px;
            border-right: 2px solid black;
            border-top-right-radius: 15px 50%;
            border-bottom-right-radius: 15px 50%;
            padding: 5px 5px 5px 0;
            input {
              text-align: center;
              width: 55px;
            }
          }
          .d-and-d-spell-slots-remaining {
            display: inline-block;
            width: calc(100% - 70px);
            text-align: center;
            height: 100%;
            vertical-align: top;
            padding: 10px 0;
            overflow: hidden;
            .d-and-d-skill-circle {
              margin: 0 3px;
            }
          }
        }
      }
      table {
        width: 100%;
        position: relative;
        .d-and-d-skill-circle {
          margin-left: 3px;
        }
        th {
          text-align: center;
          text-transform: uppercase;
          font-weight: normal;
          font-size: 6px;
        }
        tr {
          input {
            width: 100%;
            border-bottom: 1px solid rgb(150, 150, 150);
            font-size: 15px;
          }
        }
        .d-and-d-spell-prepared {
          width: 25px;
        }
      }
      .d-and-d-spell-header-labels {
        div {
          display: inline-block;
        }
        label {
          margin-bottom: 0px;
          padding-bottom: 5px;
          width: 100%;
          text-align: center;
          text-transform: uppercase;
          font-size: 6px;
        }
      }
    }
  }
  .d-and-d-box {
    .d-and-d-title {
      text-align: center;
      text-transform: uppercase;
      width: 100%;
      font-weight: bold;
      font-size: 10px;
      margin-bottom: 0px;
    }
  }
  * {
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
  }
  @media (min-width: 768px) {
    .d-and-d-character-sheet {
      .d-and-d-attribute-collection.char-name {
        width: calc(100% + 50px);
      }
    }
  }

  .header {
    display: flex;
    align-items: center;
    padding: 0.5em;

    > * + * {
      margin-left: 0.5em;
    }
  }

  .page-space {
    height: 10px;
    background-color: rgb(100, 100, 100);
  }

  @media all {
    .page-break {
      display: none;
    }
  }

  @media print {
    .dnd-sheet {
      margin: 0;
      max-width: auto;
    }

    .d-and-d-character-sheet {
      padding: 10px;
    }

    .app-holder {
      margin-top: 0px;
    }
    .page-break {
      display: block;
      page-break-before: always;
    }
    .page-space {
      background-color: transparent;
      height: 1px;
    }
    .no-print {
      display: none;
    }

    * {
      overflow: visible !important;
    }

    ::-webkit-scrollbar {
      display: none;
    }
  }

  .image {
    height: 1.5em;
    width: 100%;
    margin: 0.15em 0;
  }
}
